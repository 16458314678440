<template>
  <div class="div-scroll load">
<!--    <div class="res-bridge">-->
<!--      <chart3D ref="chart3D" :value="chartData" loading="loading13"></chart3D>-->
<!--    </div>-->
    <module-three :isExport="true"></module-three>
  </div>
</template>

<script>
  import chart3D from "./../structure/response/charts/chart3D.vue"
  import ModuleThree from '../analysis/contrast/components/moduleThree'
  export default {
    components:{chart3D,ModuleThree},
    data(){
      return{
        chartData:{
          title:'桥梁模型',
          data:{}
        },
        loading13:false
      }
    },
    created() {
      this.getData()
    },
    methods:{
      //桥模型数据
      getData(){
        // this.loading13 = true
        this.$http.get("/echart/data/wholeBridge").then(res => {
          if(res.success){
            if (res.data){
              this.$refs['chart3D'].loadEcharts(res.data)
            }
          } else {
            this.$message.error(res.msg)
          }
          // this.loading13 = false
        })
      },
    }
  }
</script>

<style scoped>
  .load{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .res-bridge{
    width: 100%;
    height: 400px;
    background: #fff;
    margin-bottom: 10px;
  }
  /deep/ .an-trent {
    height: auto;
  }
  /deep/ .an-trend-con{
    width: 100%;
  }
</style>